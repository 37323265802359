import React, {useState} from "react";
import '../css/login.css';
import { Button, FormGroup, FormControl, FormLabel } from "react-bootstrap";
import {login} from "./data/cim-server";
import ReactDOM from "react-dom";
import App from "../App";


const MyLogin =  (props) => {

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");

    function validateForm() {
        return username.length > 0 && password.length > 0;
    }

    const  handleSubmit = async (event) => {
        event.preventDefault();
        const {result,level} = await login({username: username,password: password})
        if (result == null || result === 'ko'){
            setError('Wrong username or password')
        }else{
            setError('')
            localStorage.setItem('token', result)
            localStorage.setItem('level', level)
            ReactDOM.render(<App mode={"main"} />, document.getElementById('root'));
        }
    }

    return (
        <div className="Login">

            <form onSubmit={handleSubmit}>
                <h1>CUORI IN MOVIMENTO</h1>
                <FormGroup controlId="username" bssize="large">
                    <FormLabel>Username</FormLabel>
                    <FormControl
                        autoFocus
                        type="text"
                        value={username}
                        onChange={e => setUsername(e.target.value)}
                    />
                </FormGroup>
                <FormGroup controlId="password" bssize="large">
                    <FormLabel>Password</FormLabel>
                    <FormControl
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        type="password"
                    />
                </FormGroup>
                {error !== '' && <div className="error">{error}</div>}
                <Button block bssize="large" disabled={!validateForm()} type="submit">
                    Login
                </Button>
            </form>
        </div>
    );

}

export default MyLogin
