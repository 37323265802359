import React, {useEffect} from "react";
import {deletePause, getPauses, getUserTypes, getVideos, newPausa} from "../data/cim-server";
import {Button} from "@material-ui/core";
import ReactHlsPlayer from "react-hls-player";
import DeleteIcon from "@material-ui/icons/Clear";
import ForwardIcon from "@material-ui/icons/ArrowForward";
import IconButton from "@material-ui/core/IconButton";


const MyVideoOne = (props) => {

    const [types, setTypes] = React.useState([]);
    const [pageContent, setPageContent] = React.useState({current: 'elenco', idType: 0});
    const [type, setType] = React.useState('');

    useEffect(() => {
        const ac = new AbortController()
        let isSubscribed = true;
        Promise.all([
            getUserTypes()
        ]).then(response => {
            if (isSubscribed) {
                setTypes(response[0])

            }
        }).catch(ex => console.error(ex))
        //return () => ac.abort()
        return () => {
            ac.abort()
            isSubscribed = false
        }
    }, [])

    const menuHandler = (current, id) => {
        setPageContent({current: current, idType: id})
    }

    return (
        pageContent.current === 'elenco' ?
            <ContentElenco menuHandler={menuHandler} setType={setType} types={types}/> :
            <ContentVideos menuHandler={menuHandler} type={type} id_type={pageContent.idType}/>
    )
}

const ContentElenco = (props) => {
    return (
        <div style={{textAlign: "center"}}>
            <h1>Tipo utente</h1>
            {props.types.map((item) =>
                <Item menuHandler={props.menuHandler} setType={props.setType} key={item.id} id_tipo={item.id}
                      descrizione={item.descrizione}/>
            )}
        </div>

    )
}

const ContentVideos = (props) => {

    const [videos, setVideos] = React.useState([]);

    useEffect(() => {
        const ac = new AbortController()
        let isSubscribed = true;
        Promise.all([
            getVideos(props.id_type)
        ]).then(response => {
            if (isSubscribed) {
                setVideos(response[0])
            }
        }).catch(ex => console.error(ex))
        //return () => ac.abort()
        return () => {
            ac.abort()
            isSubscribed = false
        }
    }, [props.id_type])

    return (
        <div style={{textAlign: "center"}}>
            <h1>Video per tipo utente: {props.type}</h1>
            {videos.map((item) =>
                <VideoItem key={item.id} video={item}/>
            )}
            <div style={{textAlign: "right"}}>
                <Button
                    style={{
                        width: 130,
                        height: 60,
                        border: "2px #F0F0F0 solid",
                        fontSize: "1.5em",
                        margin: "2vh",
                        borderRadius: "8px",
                    }}
                    onClick={() => {
                        props.menuHandler('elenco', 0)
                    }}
                >chiudi</Button>
            </div>
        </div>
    )
}

const VideoItem = (props) => {
    const video = props.video
    const player = React.createRef();
    return (
        <div>
            <h3>{video.titolo}</h3>
            <ReactHlsPlayer
                url={video.urlVideo}
                autoplay={false}
                controls={true}
                width="50%"
                height="auto"
                //hlsConfig={{enableWorker: true,default: true}}
                hlsConfig={{maxBufferSize: 5, maxBufferLength: 10}}
                playerRef={player}
            />
            <Pauses video={video} player={player}/>
        </div>
    )
}


const Pauses = (props) => {
    const [pauses, setPauses] = React.useState([]);
    const {video, player} = props
    const button = {
        width: 450,
        height: 40,
        border: "2px #F0F0F0 solid",
        fontWeight: "bolder",
        fontSize: "1em",
        margin: "1vh",
        borderRadius: "4px",
    }

    useEffect(() => {
        const ac = new AbortController()
        let isSubscribed = true;
        Promise.all([
            getPauses(video.id)
        ]).then(response => {
            //console.log(response)
            if (isSubscribed) {
                setPauses(response[0])
            }
        }).catch(ex => console.error(ex))
        //return () => ac.abort()
        return () => {
            isSubscribed = false
            ac.abort()

        }
    }, [video.id])

    const updateVideoPauses = () => {
        //console.log("chimato")
        Promise.all([
            getPauses(video.id)
        ]).then((response) => {
            //console.log(response[0])
            setPauses(response[0])
        }).catch(ex => console.error(ex))
    }
    return (
        <div>
            <Button
                style={button}
                onClick={async () => {
                    //console.log(Math.round(player.current.currentTime))
                    await newPausa({
                        id_video: video.id,
                        tempo_pausa: Math.round(player.current.currentTime)
                    }).then(r => {
                        if (r[0] !== null && r[0].result === "ok") {
                        } else {
                            console.log("errore inserimento pausa")
                        }
                    })
                    updateVideoPauses()

                }}
            >aggiunge una pausa misurazione</Button>

            <div style={{textAlign: "center"}}>
                {pauses.map((item) =>
                    <PausaItem updateVideoPauses={updateVideoPauses} pausa={item} key={item.id} player={player}/>
                )}
            </div>

        </div>
    )
}


const PausaItem = (props) => {
    const {updateVideoPauses, pausa, player} = props
    const handleClickDelete = async (id) => {
        await deletePause(id)
        updateVideoPauses()

    }
    const handleClickForward = (sec) => {
        player.current.currentTime = sec
    }
    return (
        <div>
            sec. {pausa.pausa} <IconButton aria-label={"elimina"} onClick={() => {
            handleClickDelete(pausa.id)
        }}>
            <DeleteIcon/>
        </IconButton>
            <IconButton onClick={() => {
                handleClickForward(pausa.pausa)
            }}>
                <ForwardIcon/>
            </IconButton>
        </div>
    )
}


const Item = (props) => {
    const button = {
        width: 250,
        height: 80,
        border: "2px #F0F0F0 solid",
        fontWeight: "bolder",
        fontSize: "2em",
        margin: "2vh",
        borderRadius: "8px",

    }
    return (
        <div>
            <Button
                style={button}
                onClick={() => {
                    props.setType(props.descrizione)
                    // console.log(props.id_tipo)
                    props.menuHandler('video', props.id_tipo)
                }}
            >
                {props.descrizione}
            </Button>
        </div>
    )
}

export default MyVideoOne
