import React from 'react';
import PrivateLayout from "./lib/private-layout";
import MyPage from "./lib/my-page";
import {LayoutProvider} from 'react-page-layout';
import MyLogin from "./lib/my-login";

const layouts = {
  private: PrivateLayout,
};


let App = (props) => {
  const {mode} = props;

  if (mode === "login") {
    return (
        <div>
          <MyLogin/>
        </div>
    );
  } else {
    return (
        <div>
          <LayoutProvider layouts={layouts}>
            <MyPage/>
          </LayoutProvider>
        </div>
    );
  }

  /**/


}

export default App;
