import React, {useEffect, useState} from "react"
import {getUsers, deleteUsers, editUser, newUser, getSessioniUtente, inviaNotifica} from "./data/cim-server"
import MyTable from "./components/my-table"
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {Videocam, Message} from "@material-ui/icons";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import TextField from "@material-ui/core/TextField";
import DialogContentText from "@material-ui/core/DialogContentText";

const MyUsers = (props) => {
    const startSortBy = [{id: "id", desc: false}]
    const sortByJson = localStorage.getItem("sortByUsers")
    const defaultSortBy = sortByJson !== null ? JSON.parse(sortByJson) : startSortBy
    const [sortBy, setSortBy] = useState(defaultSortBy)

    const startPaginationParams = {pageSize: 25, pageIndex: 0}
    const paginationParamsJson = localStorage.getItem("paginationParamsUsers")
    const defaultPaginationParams = paginationParamsJson !== null ? JSON.parse(paginationParamsJson) : startPaginationParams
    const [paginationParams, setPaginationParams] = useState(defaultPaginationParams)
    const [filterValue, setFilterValue] = useState('')
    const [skipPageReset, setSkipPageReset] = React.useState(false)
    const [users, setUsers] = useState([])
    const [usersCount, setUsersCount] = useState(0)
    const initialDialogText = "successfully saved"

    const fetchUsers = (sortBy, paginationParams, filterValue) => {
        setSkipPageReset(true)
        let sb = sortBy
        if (sb[0] === undefined) {
            if (defaultSortBy[0] !== undefined) {
                sb = defaultSortBy
            } else {
                sb = startSortBy
            }
        }
        if (paginationParams === undefined) {
            if (defaultPaginationParams !== undefined) {
                paginationParams = defaultPaginationParams
            } else {
                paginationParams = startPaginationParams
            }
        }
        return getUsers(sb[0].id, sb[0].desc ? "desc" : "asc", paginationParams, filterValue)
    }

    useEffect(() => {
        const ac = new AbortController()
        let isSubscribed = true
        Promise.all([
            fetchUsers(sortBy, paginationParams, filterValue)
        ]).then((response) => {
            if (isSubscribed) {
                setUsersCount(response[0].count)
                setUsers(customizeFields(response[0].data))
            }
        }).catch(ex => console.error(ex))
        return () => {
            ac.abort()
            isSubscribed = false
        }
    }, [paginationParams, sortBy, filterValue]) // eslint-disable-line react-hooks/exhaustive-deps

    const customizeFields = (data) => {
        return data.map(p => {
                //console.log(p)
                if (p.datada !== undefined && p.datada !== '') {
                    const dd = p.datada.split('-')
                    p = {...p, datadaConv: dd[2] + '/' + dd[1] + '/' + dd[0]}
                }
                if (p.dataa !== undefined && p.dataa !== '') {
                    const da = p.dataa.split('-')
                    p = {...p, dataaConv: da[2] + '/' + da[1] + '/' + da[0]}
                }

                if (p.ruolo === 3) {
                    p = {...p, descrizioneruolo: <RuoloCell row={p}/>}
                }

                if (p.misurazioni !== 0) {
                    p = {...p, descrizioneMisurazioni: 'Si'}
                } else {
                    p = {...p, descrizioneMisurazioni: 'No'}
                }

                return p
            }
        )
    }

    const RuoloCell = (props) => {
        const {row} = props
        const [openAttivita, setOpenAttivita] = React.useState(false)
        const [openNotifica, setOpenNotifica] = React.useState(false)
        const [attivita, setAttivita] = React.useState([])
        const [notifica, setNotifica] = React.useState({titolo: "", testo: ""})
        const scroll = 'paper'
        const [dialogText, setDialogText] = React.useState({text: "I campi con * sono obbligatori", color: "inherit"});
        const [notificaInviata, setNotificaInviata] = React.useState(false)

        const handleCloseAttivita = () => {
            setOpenAttivita(false)
        };
        const handleClickOpenAttivita = (id) => {
            const ac = new AbortController()
            let isSubscribed = true
            Promise.all([
                getSessioniUtente(row.id)
            ]).then(response => {
                if (isSubscribed) {
                    setAttivita(response[0])
                    setOpenAttivita(true)
                }
            }).catch(ex => console.error(ex))
            return () => {
                ac.abort()
                isSubscribed = false
            }
        }

        const handleCloseNotifica = () => {
            setOpenNotifica(false)
        };
        const handleClickOpenNotifica = (id) => {
            setNotificaInviata(false)
            setNotifica({titolo: "", testo: ""})
            setDialogText({text: "I campi con * sono obbligatori", color: "inherit"})
            setOpenNotifica(true)
        }

        const handleChange = name => ({target: {value}}) => {
            setNotifica({...notifica, [name]: value})
        }

        const handleInviaNotifica = (idUtente) => {
            if (notifica.titolo.trim() === "") {
                setDialogText({
                    text: "Il titolo è obbligatorio",
                    color: "error"
                })
            } else if (notifica.testo.trim() === "") {
                setDialogText({
                    text: "Il testo è obbligatorio",
                    color: "error"
                })
            } else {
                inviaNotifica(idUtente, notifica).then(r => {
                    if (r[0] !== null && r[0].result === "ok") {
                        setNotificaInviata(true)
                        setDialogText({
                            text: "Messaggio inviato",
                            color: "inherit"
                        })
                    } else {
                        setNotificaInviata(true)
                        setDialogText({
                            text: "Messaggio non inviato, riprova più tardi",
                            color: "error"
                        })
                    }

                })
            }

        };

        return (
            <div>
                {row.descrizioneruolo}
                <Tooltip title={"attività"}>
                    <IconButton aria-label={"mostra"} onClick={() => handleClickOpenAttivita(row.id)}>
                        {<Videocam/>}
                    </IconButton>
                </Tooltip>
                <Tooltip title={"notifica"}>
                    <IconButton aria-label={"mostra"} onClick={() => handleClickOpenNotifica(row.id)}>
                        {<Message/>}
                    </IconButton>
                </Tooltip>
                <Dialog
                    open={openNotifica}
                    onClose={handleCloseNotifica}
                >
                    <DialogTitle id="scroll-dialog-title">Invia una notifica a: {row.nome} {row.cognome}</DialogTitle>
                    <DialogContent>
                        <DialogContentText color={dialogText.color}>{dialogText.text}</DialogContentText>
                        {!notificaInviata && <TextField
                            autoFocus
                            margin="dense"
                            label="* Titolo"
                            type="text"
                            fullWidth
                            value={notifica.titolo}
                            onChange={handleChange('titolo')}
                        />}
                        {!notificaInviata && <TextField
                            //autoFocus
                            margin="dense"
                            label="* Testo"
                            type="text"
                            fullWidth
                            value={notifica.testo}
                            onChange={handleChange('testo')}
                        />}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseNotifica} color="primary">
                            chiudi
                        </Button>
                        {!notificaInviata && <Button onClick={() => handleInviaNotifica(row.id)} color="secondary">
                            invia
                        </Button>}
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={openAttivita}
                    onClose={handleCloseAttivita}
                    scroll={scroll}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                    maxWidth={'lg'}
                >
                    <DialogTitle id="scroll-dialog-title">Attività utente: {row.nome} {row.cognome}</DialogTitle>
                    <DialogContent dividers={scroll === 'paper'}>
                        <List>
                            <ListItem key={0}>
                                <ListItemText style={{width: 250}} primary={'data'}/>
                                <ListItemText style={{width: 250}} primary={'video'}/>
                                <ListItemText style={{width: 250}} primary={'completato'}/>
                                <ListItemText style={{width: 250}} primary={'pause'}/>
                                <ListItemText style={{width: 250}} primary={'livello batt.'}/>
                                <ListItemText style={{width: 250}} primary={'misurazione'}/>
                            </ListItem>
                            {
                                attivita.map((a) => {
                                    const dataSessione = new Date(a.sessione);
                                    let misurazioneTxt = "";
                                    let misurazioneStyle = {};
                                    if (a.misurazione_bloccato !== "") {
                                        misurazioneTxt = a.misurazione_bloccato;
                                        misurazioneStyle = {width: 250, color: "red"};
                                    } else if (a.misurazione_ko !== "") {
                                        misurazioneTxt = a.misurazione_ko;
                                        misurazioneStyle = {width: 250, color: "orange"};
                                    } else {
                                        misurazioneTxt = a.misurazione_ok;
                                        misurazioneStyle = {width: 250, color: "green"};
                                    }
                                    return (
                                        <ListItem key={a.sessione}>
                                            <ListItemText style={{width: 250}} primary={dataSessione.toLocaleString()}/>
                                            <ListItemText style={{width: 250}} primary={a.titolo}/>
                                            <ListItemText style={{width: 250}} primary={a.completed ? 'SI' : 'NO'}/>
                                            <ListItemText style={{width: 250}} primary={a.paused}/>
                                            <ListItemText style={{width: 250}} primary={a.livello_batteria}/>
                                            <ListItemText style={misurazioneStyle} primary={misurazioneTxt}/>
                                        </ListItem>
                                    )
                                })
                            }
                        </List>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseAttivita} color="primary">
                            chiudi
                        </Button>

                    </DialogActions>
                </Dialog>
            </div>

        )
    }


    const onChangeSort = (sortBy) => {
        setSortBy(sortBy)
        const sortByJson = JSON.stringify(sortBy)
        localStorage.setItem("sortByUsers", sortByJson)
    }

    const onChangePaginationParams = (paginationParams) => {
        setPaginationParams(paginationParams)
        const paginationParamsJson = JSON.stringify(paginationParams)
        localStorage.setItem("paginationParamsUsers", paginationParamsJson)
    }

    const filter = (value) => {
        if (value !== undefined && value.length > 2) {
            setFilterValue(value)
        } else {
            setFilterValue('')
        }

    }
    const onModifyTableContent = () => {
        Promise.all([
            fetchUsers(sortBy, paginationParams, filterValue)
        ]).then((response) => {
            setUsersCount(response[0].count)
            setUsers(customizeFields(response[0].data))
        }).catch(ex => console.error(ex))
    }

    const addUserHandler = (user, mode) => {
        let result = {}
        if (mode === "add") {
            newUser(user).then(r => {
                if (r[0] !== null && r[0].result === "ok") {
                    onModifyTableContent()
                    result = {text: initialDialogText, open: true}
                } else {
                    result = {text: "save failed, please try again later", open: true}
                }
            })
        } else {
            editUser(user).then(r => {
                if (r[0] !== null && r[0].result === "ok") {
                    onModifyTableContent()
                    result = {text: initialDialogText, open: true}
                } else {
                    result = {text: "save failed, please try again later", open: true}
                }
            })
        }
        return result
    }

    const deleteUsersHandler = (users) => {
        let result = {}
        deleteUsers(users).then(r => {
            if (r[0] !== null && r[0].result === "ok") {
                onModifyTableContent()
                result = {text: initialDialogText, open: true}
                //setDialog({text: initialDialogText, open: true})
            } else {
                result = {text: "save failed, please try again later", open: true}
                //setDialog({text: "save failed, please try again later", open: true})
            }
        })
        return result
    }


    const columns = React.useMemo(() => [
        {
            Header: "id",
            accessor: "id"
        },
        {
            Header: "Nome",
            accessor: "nome"
        },
        {
            Header: "Cognome",
            accessor: "cognome"
        },
        {
            Header: "Tipo",
            accessor: "descrizionetipo"
        },
        {
            Header: "Ruolo",
            accessor: "descrizioneruolo",
        },
        {
            Header: "Valido dal",
            accessor: "datadaConv"
        },
        {
            Header: "Valido al",
            accessor: "dataaConv"
        },
        {
            Header: "Misurazioni",
            accessor: "descrizioneMisurazioni"
        }
    ], [])
    return (
        <div>
            <MyTable
                columns={columns}
                data={users}
                initialSortBy={defaultSortBy}
                onModifyTableContent={onModifyTableContent}
                onChangeSort={onChangeSort}
                skipPageReset={skipPageReset}
                onChangePaginationParams={onChangePaginationParams}
                initialPaginationParams={defaultPaginationParams}
                recordsCount={usersCount}
                addHandler={addUserHandler}
                deleteHandler={deleteUsersHandler}
                tableName="users"
                tableTitle="Utenti"
                filter={filter}
            />
        </div>
    )
}
export default MyUsers
