import React, {useEffect} from 'react';

import MaUTable from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import TableContainer from '@material-ui/core/TableContainer'

import PropTypes from 'prop-types';

import {useRowSelect, useSortBy, useTable, usePagination} from 'react-table';
import TableToolbar from "./table-toolbar";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TablePaginationActions from "./table-pagination-actions";

const initialDialog = {
    text: "successfully saved",
    open: false
}
const IndeterminateCheckbox = React.forwardRef(
    ({indeterminate, ...rest}, ref) => {
        const defaultRef = React.useRef()
        const resolvedRef = ref || defaultRef

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])

        return (
            <>
                <Checkbox ref={resolvedRef} {...rest} />
            </>
        )
    }
)


const MyTable = ({columns, data, initialSortBy, onChangeSort, skipPageReset,onChangePaginationParams,initialPaginationParams,recordsCount,addHandler,deleteHandler,tableName,tableTitle,filter}) => {

    const [dialog, setDialog] = React.useState(initialDialog);
    const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
    //const [editDialogOpen, setEditDialogOpen] = React.useState(false);
    const {
        getTableProps,
        headerGroups,
        rows,
        prepareRow,
        selectedFlatRows,
        gotoPage,
        setPageSize,
        state: {
            sortBy,
            pageSize,
            pageIndex,
            selectedRowIds
        }
    } = useTable({
            columns,
            data,
            autoResetPage: true,
            initialState: {sortBy: initialSortBy, pageSize: initialPaginationParams.pageSize, pageIndex: initialPaginationParams.pageIndex},
            manualSortBy: true,
            manualPagination: true
        },
        useSortBy,
        usePagination,
        useRowSelect,
        hooks => {
            hooks.allColumns.push(columns => [
                // Let's make a column for selection
                {
                    id: 'selection',
                    // The header can use the table's getToggleAllRowsSelectedProps method
                    // to render a checkbox.  Pagination is a problem since this will select all
                    // rows even though not all rows are on the current page.  The solution should
                    // be server side pagination.  For one, the clients should not download all
                    // rows in most cases.  The client should only download data for the current page.
                    // In that case, getToggleAllRowsSelectedProps works fine.
                    Header: ({getToggleAllRowsSelectedProps}) => (
                        <div>
                            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                        </div>
                    ),
                    // The cell can use the individual row's getToggleRowSelectedProps method
                    // to the render a checkbox
                    Cell: ({row}) => (
                        <div>
                            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                        </div>
                    ),
                },
                ...columns,
            ])
        });
    useEffect(() => {
        onChangeSort(sortBy);
    }, [onChangeSort, sortBy]);



    const deleteRecordHandler = _ => {
        setDeleteDialogOpen(true);
    }

    const handleCloseDialog = () => {
        setDialog({...dialog, open: false});
    }

    const handleCloseDeleteDialog = () => {
        setDeleteDialogOpen(false);
    }

    const handleOkDeleteDialog = () => {
        const records = [];
        selectedFlatRows.forEach(r => {
            records.push(r.original.id)
        })
        deleteRecord(records)
        setDeleteDialogOpen(false);
    }

    const handleChangePage = (event, newPage) => {
        //console.log("newPage -> "+newPage+" "+pageIndex+" "+pageSize);
        onChangePaginationParams({pageSize: pageSize, pageIndex: newPage});
        gotoPage(newPage)

    }

    const deleteRecord = (records) => {
        setDialog(deleteHandler(records))
    }

    const addRecord = (record, mode) => {
        setDialog(addHandler(record, mode))
    }

    const filterHandler = (value) => {
        filter(value)
    }

    const handleChangeRowsPerPage = event => {
        setPageSize(Number(event.target.value));
        onChangePaginationParams({pageSize: Number(event.target.value), pageIndex: 0});
        gotoPage(0);
    }

    return (
        <div>
            <Dialog
                open={deleteDialogOpen}
                onClose={handleCloseDeleteDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Delete the {selectedFlatRows.length} selected records?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteDialog} color="primary" autoFocus>
                        Cancel
                    </Button>
                    <Button onClick={handleOkDeleteDialog} color="primary" autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={dialog.open !== undefined ? dialog.open : false}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {dialog.text}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary" autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
            <TableContainer>
                <TableToolbar
                    filterHandler={filterHandler}
                    addHandler={addRecord}
                    numSelected={Object.keys(selectedRowIds).length}
                    deleteHandler={deleteRecordHandler}
                    selectedFlatRows={selectedFlatRows}
                    tableName={tableName}
                    title={tableTitle}
                />
                <MaUTable {...getTableProps()}>
                    <TableHead>
                        {headerGroups.map(headerGroup => (
                            <TableRow {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <TableCell {...(column.id === 'selection'
                                        ? column.getHeaderProps()
                                        : column.getHeaderProps(column.getSortByToggleProps()))}>
                                        {column.render('Header')}
                                        {column.id !== 'selection' ? (
                                            <TableSortLabel
                                                active={column.isSorted}
                                                // react-table has a unsorted state which is not treated here
                                                direction={column.isSortedDesc ? 'desc' : 'asc'}
                                            />
                                        ) : null}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableHead>
                    <TableBody>
                        {rows.map((row, i) => {
                            prepareRow(row)
                            return (
                                <TableRow {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        return (
                                            <TableCell {...cell.getCellProps()}>
                                                {
                                                    typeof cell.value !== "boolean"? cell.render('Cell'): cell.value ? "Y":"N"
                                                }
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                            )
                        })}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                colSpan={3}
                                count={recordsCount}
                                rowsPerPage={pageSize}
                                page={pageIndex}
                                SelectProps={{
                                    inputProps: {'aria-label': 'rows per page'},
                                    native: true,
                                }}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                                rowsPerPageOptions={[
                                    {label: '5', value: 5 },
                                    {label: '10', value: 10},
                                    {label: '25', value: 25},
                                    {label: 'All', value: recordsCount},
                                ]}
                            />
                        </TableRow>
                    </TableFooter>
                </MaUTable>
            </TableContainer>
        </div>
    )

}

MyTable.propTypes = {
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    initialSortBy: PropTypes.array.isRequired,
    onChangeSort: PropTypes.func.isRequired,
    onModifyTableContent: PropTypes.func.isRequired,
    skipPageReset: PropTypes.bool.isRequired,
    onChangePaginationParams: PropTypes.func.isRequired,
    initialPaginationParams: PropTypes.object.isRequired,
    addHandler: PropTypes.func.isRequired,
    deleteHandler: PropTypes.func.isRequired,
    recordsCount: PropTypes.number.isRequired,
    tableName: PropTypes.string.isRequired,
    tableTitle: PropTypes.string.isRequired,
    filter: PropTypes.func.isRequired,
}

export default MyTable
