import React, {PureComponent} from 'react'
import {Page, Section} from 'react-page-layout'
import MyUsers from "./my-users"
import MyVideoOne from "./my-video/my-video-one";
import {MenuItem, MenuList} from "@material-ui/core";

class MyPage extends PureComponent {

    state = {
        layout: 'private',
        title: 'CUORI IN MOVIMENTO',
        contentSectionName: 'content',
        currentContent: 'calendar',
    }


    menuHandler = (e, page) => {
        e.preventDefault()
        this.setState({currentContent: page})
    }

    renderSidebar() {
        const currentContent = this.state.currentContent
        const level = localStorage.getItem('level')
        return (
            <Section slot="sidebar">
                <MenuList>
                    {level === '1' ?
                        <MenuItem style={currentContent === 'users' ? {color: "white"} : {color: "#aeb2b7"}}
                                  onClick={(e) => this.menuHandler(e, 'users')}>
                            Utenti
                        </MenuItem> :
                        <div></div>
                    }

                    {level === '1' || level === '2' ?
                        <MenuItem style={currentContent === 'videos' ? {color: "white"} : {color: "#aeb2b7"}}
                                  onClick={(e) => this.menuHandler(e, 'videos')}>
                            Video
                        </MenuItem> :
                        <div></div>
                    }
                </MenuList>
            </Section>
        )
    }

    renderFooter() {
        return (
            <Section slot="footer">
                Footer content
            </Section>
        )
    }

    render() {
        const {layout, title, contentSectionName, currentContent} = this.state

        return (
            <Page layout={layout} title={title}>
                {this.renderSidebar()}
                <Section slot={contentSectionName}>
                    {currentContent === 'users' && <MyUsers/>}
                    {currentContent === 'videos' && <MyVideoOne/>}
                </Section>
                {this.renderFooter()}
            </Page>
        )
    }
}

export default MyPage
