import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

let mode = "login";

//Verifico se localmente esiste un token
let token = localStorage.getItem('token')
if (token !== null && token.length > 0){
    mode = "main"
}
ReactDOM.render(<App mode={mode} />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
