import axios from 'axios';
import * as Constants from '../constants'


const login = async (user) => {
    let result = await axios.post(Constants.APIURL, {
        token: localStorage.getItem('token'),
        mode: "login",
        username: user.username,
        password: user.password
    });
    return result.data;
}

const getUsers = async (sort, order,paginationParams,filterValue) => {
    let result = await axios.post(Constants.APIURL, {
        token: localStorage.getItem('token'),
        mode: "users",
        sort: sort,
        order: order,
        pageIndex: paginationParams.pageIndex,
        pageSize: paginationParams.pageSize,
        filterValue: filterValue
    });
    return result.data
}
const deleteUsers = async (users) => {
    let result = await axios.post(Constants.APIURL, {
        token: localStorage.getItem('token'),
        mode: "delete_users",
        users: users
    });
    return result.data;
}

const editUser = async (user) => {
    let result = await axios.post(Constants.APIURL, {
        token: localStorage.getItem('token'),
        mode: "edit_user",
        user: user
    });
    return result.data;
}

const newUser = async (user) => {
    let result = await axios.post(Constants.APIURL, {
        token: localStorage.getItem('token'),
        mode: "save_user",
        user: user
    });
    return result.data;
}

const inviaNotifica = async (idUtente, notifica) => {
    let result = await axios.post(Constants.APIURL, {
        token: localStorage.getItem('token'),
        mode: "send_notice",
        id_user: idUtente,
        notice: notifica
    });
    return result.data;
}

const newPausa = async (pausa) => {
    let result = await axios.post(Constants.APIURL, {
        token: localStorage.getItem('token'),
        mode: "save_pausa",
        pausa: pausa
    });
    return result.data;
}

const getUserTypes = async () => {
    let result = await axios.post(Constants.APIURL, {
        token: localStorage.getItem('token'),
        mode: "user_types",
    });
    return result.data;
}

const getVideos = async (idTipo) => {
    let result = await axios.post(Constants.APIURL, {
        token: localStorage.getItem('token'),
        mode: "videos",
        id_tipo: idTipo
    });
    return result.data;
}

const getPauses = async (idVideo) => {
    let result = await axios.post(Constants.APIURL, {
        token: localStorage.getItem('token'),
        mode: "pauses",
        id_video: idVideo
    });
    return result.data;
}

const deletePause = async (idPause) => {
    let result = await axios.post(Constants.APIURL, {
        token: localStorage.getItem('token'),
        mode: "delete_pause",
        id_pause: idPause
    });
    return result.data;
}

const getSessioniUtente = async (id) => {
    let result = await axios.post(Constants.APIURL, {
        token: localStorage.getItem('token'),
        mode: "sessioni_utente",
        id_utente: id
    });
    return result.data;
}

export {
    login,
    getUsers,
    deleteUsers,
    editUser,
    newUser,
    getUserTypes,
    getSessioniUtente,
    getVideos,
    newPausa,
    getPauses,
    deletePause,
    inviaNotifica
}
